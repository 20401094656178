<div id="test1" class="col s12"><br>
  <div class="container" *ngIf="userSubscription">

    <div class="card" *ngIf="userSubscription.status == 'cancelled'">
      <div class="card-content" align="center">
        <br> <span> You don't currently have an active Pyramid Pass. choose a plan below to get rolling</span>
      </div>
      <div align="center" class="card-action">
        <a class="btn btn-flat blue-text" (click)="openSelfServePortal()">Manage Billing Preferences</a>

      </div>
    </div><br>

    <div class="card" *ngIf="userSubscription.status != 'cancelled'">
      <div class="card-content">

        <h5 *ngIf="userPlan && userSubscription.status != 'paused'" class="card-title">{{userPlan.plan.name}}</h5>
        <h5 *ngIf="userSubscription.status == 'paused'" class="card-title">Your Pass is Paused</h5>
        <!-- <h6 class="card-subtitle mb-2 text-muted" *ngIf="userSubscription.plan_id != 'amz30d'">
          {{userSubscription.plan_amount/100}}
          {{userSubscription.currency_code}}/{{userSubscription.billing_period_unit}}
        </h6> -->
        <p class="card-text" *ngIf="userdata.auto_renew_off && userSubscription.status == 'in_trial'">Auto
          Renewal is off </p>
        <p class="card-text" *ngIf="userSubscription.status == 'in_trial'">Trial Start/End
          {{userSubscription.trial_start * 1000 | date}} -- {{userSubscription.trial_end * 1000 | date}}
        </p>
        <p>Subscribed at
          <strong>{{userSubscription.started_at * 1000 | date}}</strong>
        </p>
        <p
          *ngIf="(userSubscription.status == 'in_trial' || userSubscription.status == 'active') && !userSubscription.cancelled_at && !userSubscription.pause_date">
          Next Billing of {{userSubscription.plan_amount/100}}
          {{userSubscription.currency_code}}/{{userSubscription.billing_period_unit}} at
          <strong>{{userSubscription.next_billing_at * 1000 | date}}</strong>
        </p>

        <p *ngIf="userSubscription.pause_date">
          Pause Date
          <strong>{{userSubscription.pause_date * 1000 | date}}</strong>
        </p>
        <p *ngIf="userSubscription.resume_date">
          Resume Date
          <strong>{{userSubscription.resume_date * 1000 | date}}</strong>
        </p>

        <p
          *ngIf="(userSubscription.status == 'non_renewing' || (userSubscription.status== 'in_trial') && userSubscription.cancelled_at)">
          Cancels at
          <strong>{{userSubscription.cancelled_at * 1000 | date}}</strong>
        </p>

        <p *ngIf="userSubscription.status == 'cancelled'">Cancelled on
          <strong>{{userSubscription.cancelled_at * 1000 | date}}</strong>
        </p>

        <button style="margin-top:10px"
          *ngIf="(userSubscription.status == 'in_trial' || userSubscription.status == 'active') && !userSubscription.cancelled_at && !userSubscription.pause_date"
          class="btn waves-effect red waves-light" (click)="cancel(userSubscription.id)">End my subscription</button>

        <button
          *ngIf="userSubscription.status == 'non_renewing' || userSubscription.status == 'cancelled'|| (userSubscription.status== 'in_trial' && userSubscription.cancelled_at)"
          class="btn waves-effect waves-light" (click)="reactivate(userSubscription.id)">Keep my subscription</button>

        <button style="margin-top:5px" *ngIf="userSubscription.status == 'paused'" class="btn waves-effect waves-light"
          (click)="reactivate(userSubscription.id)"><i class="material-icons left">play_arrow</i>Resume Pass</button>

        <button style="margin-left: 1em; margin-top:10px"
          *ngIf="userSubscription.status == 'active' && !userSubscription.cancelled_at && showPause && userSubscription.plan_id != 'amz30d' && !userSubscription.pause_date"
          class="btn waves-effect waves-light" (click)="pause(userSubscription.id)"><i
            class="material-icons left">pause</i>Pause</button>

        <button *ngIf="userSubscription.pause_date && userSubscription.status != 'paused' "
          class="btn waves-effect waves-light" style="margin-top: 10px;"
          (click)="removeScheduledPause(userSubscription.id)"><i class="material-icons left">play_arrow</i>Remove
          scheduled
          pause</button>

      </div>
      <div class="card-action" align="center">
        <button *ngIf="userdata && !userdata.devAccount && userSubscription.status != 'paused'"
          class="waves-effect waves-light btn" (click)="openSelfServePortal()">Manage Account</button>



        <button *ngIf="userdata && !userdata.devAccount && userSubscription.status == 'paused'"
          class="btn btn-flat blue-text" (click)="openSelfServePortal()">Manage Account</button>

      </div>
    </div><br>





    <!-- pick a plan -->
    <div class="card" *ngIf="userSubscription.status == 'cancelled' && plans.length > 0">
      <!-- This condition is here because plan if plan is cancelled in trial state then it doesnot go to non renewing state -->
      <!--  || (userSubscription.status== 'in_trial' && userSubscription.cancelled_at) -->
      <div class="card-content">

        <h5>Pick a Plan</h5>
        <ul class="collapsible" materialize="collapsible">
          <li *ngFor="let plan of plans">
            <div class="collapsible-header"><i class="material-icons">place</i>{{plan.plan.name}}</div>
            <div class="collapsible-body">
              <div class="card-body">
                <h5 class="card-title">{{plan.plan.name}}</h5>
                <h6 *ngIf="!isUSDPlan" class="card-subtitle mb-2 text-muted">
                  £{{plan.plan.price/100}}/{{plan.plan.period_unit}}</h6>
                <h6 *ngIf="isUSDPlan" class="card-subtitle mb-2 text-muted">
                  ${{plan.plan.price/100}}/{{plan.plan.period_unit}}</h6>
                <p class="card-text">{{plan.plan.description}}</p>
                <p class="card-text" *ngIf="plan.plan.trial_period">Trial Period {{plan.plan.trial_period}}
                  {{plan.plan.trial_period_unit}}(s)
                </p>
                <button class="btn btn-primary" (click)="openCheckoutExisting(plan.plan.id)">Choose this plan</button>
              </div>
            </div>

          </li>
        </ul>
      </div>


    </div>
  </div><br>


  <div class="container" *ngIf="userdata && userdata.devAccount">
    <div class="card">
      <div class="card-content">


        <h4 *ngIf="userdata.vpnAccountStatus == 'success'"> Active </h4>

        <h4 *ngIf="userdata.vpnAccountStatus == 'fail'"> Inactive </h4>
        <p>Account type : Developer</p>
        <p>Expires : Never </p>
      </div><br>


    </div><br>
  </div>