<div id="login-page" class="row">
  <p align="center"> <a routerLink='/login'> <img src="/assets/pyramid-logo.svg" /> </a> </p>
  <div class="col s12 z-depth-6 card-panel">
    <form class="login-form" [formGroup]="registerForm" (ngSubmit)="onRegister(registerForm.value)">
      <div class="row"><br>
        <div class="input-field col s12">
          <input formControlName="full_name" id="name" type="text" class="validate">
          <label for="name">Full Name</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input formControlName="email" id="email" type="email" class="validate">
          <label for="email">Email</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input formControlName="password" id="password" type="password" class="validate">
          <label for="password">Password</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s4">
          <input formControlName="post_code" id="postcode" type="text" class="validate">
          <label for="postcode">Post/Zip Code</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12">
          <button type="submit" [disabled]="!registerForm.valid"
            class="btn waves-effect waves-light col s12">Activate</button>
        </div>
      </div>
      <div class="row">


      </div>

    </form>
  </div>
</div>