<div id="test1" class="col s12">
    <div class="container">
        <br>
        <div class="card orange  lighten-2">

            <div class="card-content black-text" align="center">
                <p>⚠ Items tied to your Pyramid membership will be affected if you cancel your membership.
                </p><br>
                <p>By cancelling, you will no longer have access to Pyramid servers for protecting privacy or
                    unlocking content.</p>



            </div>
        </div>


        <div class="card">
            <div class="card-image">
                <img src="./assets/cancel.png">
            </div>
            <div class="card-content" align="center">
                <br> <span> Benefits of Pyramid include unlocking websites and streaming websites globally,
                    protecting your privacy and helping keep you secure.</span>
            </div>


            <div align="center" class="card-action">
                <a class="waves-effect waves-light btn" (click)="toHome()">Keep these Benefits</a>

            </div>
        </div>

        <div class="row" *ngIf="userSubscription">
            <div align="center" class="col s12 m4">
                <button (click)="reminder()" class="teal accent-3 waves-effect waves-light btn black-text">
                    <i class="material-icons left">email</i>Remind Me Later</button>

                <p>Get an email reminder 3 days before renewal.</p>
            </div>

            <div align="center" class="col s12 m4"><a (click)="cancel(userSubscription.id)"
                    class="teal accent-3 waves-effect waves-light btn black-text">I
                    Want to Cancel</a>

            </div>
            <div *ngIf="userSubscription.status == 'active' && !userSubscription.cancelled_at && showPause"
                align="center" class="col s12 m4"><a (click)="pause(userSubscription.id)" pause
                    class="teal accent-3 waves-effect waves-light btn black-text">Pause
                    Subscription</a>

            </div>
        </div>



        <p align="center"> If you are experiencing a technical problem, reach out through our <a
                href="https://www.pyramidwifi.com/help" target="_blank?">helpdesk</a> so we can fix it.</p>
    </div>

</div>