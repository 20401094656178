import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-invite",
  templateUrl: "./invite.component.html",
  styleUrls: ["./invite.component.css"],
})
export class InviteComponent implements OnInit, OnDestroy {
  userdata;
  userSubscription;
  userDataSubscription: Subscription;
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.userDataSubscription = this.api
      .getUserDataFromFirbease(localStorage.getItem("useruuid"))
      .subscribe((data) => {
        this.userdata = data;
        if (!this.userdata.devAccount) {
          this.api
            .getSubscritpion(this.userdata.subscription_id)
            .subscribe((subscription) => {

              this.userSubscription = subscription;
              this.api.saveProfile(localStorage.getItem("useruuid"), {
                plan_id: subscription["plan_id"],
                subscription_id: subscription["id"],
                subscription_date: subscription["created_at"],
                subscription_update_date: subscription["updated_at"],
                next_billing_at: subscription["next_billing_at"] || null,
                subscription_status: subscription["status"],
                cancelled_at: subscription["cancelled_at"] || null,
                pause_date: subscription["pause_date"] || null,
                resume_date: subscription["resume_date"] || null,
              });
            });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  async copyInvite() {
    if (this.userdata.inviteCode) {
      this.copyToClipBoard(
        `pyramidwifi.com/invite?${this.userdata.inviteCode}`
      );
    } else {
      // generate the code and save it and copy it

      const inviteCode =
        this.userdata.full_name.split(" ")[0] +
        Math.random().toString(36).substring(2, 7).toUpperCase();

      await this.api.saveProfile(localStorage.getItem("useruuid"), {
        inviteCode: inviteCode,
      });
      this.copyToClipBoard(`pyramidwifi.com/invite?${inviteCode}`);
    }
  }

  copyToClipBoard(text) {
    navigator.clipboard
      .writeText(text)
      .then()
      .catch((e) => console.error(e));
  }
}
