import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { HomeComponent } from "./home/home.component";
import { AuthGuard } from "./guard/auth.guard";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { InvitedComponent } from "./invited/invited.component";
import { RegisterDevComponent } from "./register-dev/register-dev.component";
import { MySubscriptionComponent } from "./my-subscription/my-subscription.component";
import { RedeemCodeComponent } from "./redeem-code/redeem-code.component";
import { InviteComponent } from "./invite/invite.component";
import { UpdatePasswordComponent } from "./update-password/update-password.component";
import { IndexComponent } from "./index/index.component";
import { CancelComponent } from "./cancel/cancel.component";
import { ActivationComponent } from "./activation/activation.component";
import { SupportComponent } from "./support/support.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  { path: "register/:plan_id", component: RegisterComponent },
  { path: "registerdev", component: RegisterDevComponent },
  { path: "activate", component: ActivationComponent },
  { path: "reset", component: ResetPasswordComponent },
  { path: "invited", component: InvitedComponent },
  {
    path: "home",
    component: HomeComponent,
    children: [
      {
        path: "",
        redirectTo: "index",
        pathMatch: "full",
      },
      {
        path: "index",
        component: IndexComponent,
      },
      {
        path: "mysubscription",
        component: MySubscriptionComponent,
      },
      {
        path: "cancel",
        component: CancelComponent,
      },
      // {
      //   path: "redeem",
      //   component: RedeemCodeComponent,
      // },
      // {
      //   path: "invite",
      //   component: InviteComponent,
      // },
      {
        path: "update-password",
        component: UpdatePasswordComponent,
      },
      {
        path: "support",
        component: SupportComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
