<div id="test1" class="col s12">
  <div class="container" *ngIf="userdata && !userdata.devAccount">
    <div class="card">
      <div class="card-content">

        <ul class="collapsible" materialize="collapsible">
          <li class="active">
            <div class="collapsible-header active"><i class="material-icons">confirmation_number</i>Redeem Code
            </div>
            <div class="collapsible-body">

              <div class="input-field col s12">
                <i class="material-icons prefix">confirmation_number</i>
                <input [(ngModel)]="redeem_code" id="redeem" type="text">
                <label for="redeem">Enter Gift Card or promotional code</label>
              </div>

              <button class="waves-effect waves-light btn" (click)="redeem()">Apply Code</button>


            </div>
          </li>
          <li>
            <div class="collapsible-header"><i class="material-icons">favorite_border
              </i>Can I apply an invite code?</div>
            <div class="collapsible-body"><span>No, as you have already have an active account it is no
                longer possible to apply an invite code which can only be applied during
                activation.<br><br>If you would like to invite someone else to join Pyramid, you can
                earn rewards using your unique invite code by <a href="invite.html">tapping
                  here.</a></span>
              <br><br>
            </div>
          </li>

          <li>
            <div class="collapsible-header"><i class="material-icons">help
              </i>Have an issue?</div>
            <div class="collapsible-body"><span>If you have any issues applying a code please reach out
                to us at <a href="mailto:help@pyramidwifi.com">help@pyramidwifi.com</a>.</span>
              <br><br>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </div>
</div>
