import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {
  code: string
  constructor(private router: Router, private api: ApiService) { }

  ngOnInit() {
  }

  async activate() {
    let resp = await this.api.getPlanCode(this.code).pipe(take(1)).toPromise();
    if (resp['success']) {
      if (resp['data'][0].redirectUrl) {
        window.location.href = resp['data'][0].redirectUrl;
      } else {
        this.router.navigateByUrl(`register/${resp['data'][0].plan}`)
      }
    } else {
      this.api.showCustomAlertError("Invalid Activation Code")
    }

  }

}
