<div id="login-page" class="row bg">
    <p align="center"><a routerLink='/login'><img src="/assets/pyramid-logo.svg" /></a></p>
    <div class="col s12 z-depth-6 card-panel">
        <div class="login-form">
            <div class="row">
            </div>
            <div class="row">
                <div class="input-field col s12">
                    <i class="material-icons prefix">redeem</i>
                    <input [(ngModel)]="code" class="validate" id="activation" type='text'>
                    <label for="activation">Enter Activation Code</label>
                </div>
            </div>
            <div class="row">
                <div class="input-field col s12">
                    <button (click)="activate()" class="btn waves-effect waves-light col s12">Activate</button>
                </div>
            </div>
        </div>
    </div>


</div>