<nav>
  <div class="nav-wrapper purple darken-2">

    <div class="row">
      <div class="col s4">
        <a materialize="sideNav" [materializeParams]="[{edge:'left', closeOnClick:true}]" data-activates='mobile-demo'
          class="sidenav-trigger"><i class="material-icons hide-on-large-only">menu</i></a>
      </div>
      <div class="col s4">
        <a class="brand-logo hide-on-large-only"><img src="/assets/pyramid-imagemark.svg"></a>

      </div>
      <div class="col s4">
        <ul class="right">

          <li><a materialize="dropdown" class="dropdown-trigger" data-activates='dropdown1'>
              <i class="material-icons right">more_vert</i>
              <span style="visibility:hidden">dropdown goes here</span></a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ul style="width: 175px !important;" id="dropdown1" class="dropdown-content">
    <li><a href="https://www.pyramidwifi.com/settings" target="_blank">Pyramid Settings</a></li>
    <li><a href="https://www.pyramidwifi.com/product" target="_blank">Visit Pyramid Store</a></li>
    <li><a [routerLink]="'/home/update-password'">Update Password</a></li>
    <li class="divider"></li>
    <li><a (click)="logout()">Sign Out</a></li>
  </ul>

  <ul id="mobile-demo" class="side-nav fixed">
    <li>
      <div class="user-view">
        <div class="background teal lighten-2">

        </div>
        <a><img class="circle" src="/assets/pyramid-circle.jpg"></a>

        <a class="white-text">Manage Account</a><br>

      </div>
    </li>
    <li><a [routerLink]="'/home/index'">Home<i class="material-icons">home
        </i></a></li>
    <li><a [routerLink]="'/home/mysubscription'">My Subscription<i class="material-icons">local_play
        </i></a></li>

    <li>
      <div class="divider"></div>
    </li>

    <li *ngIf="this.isUSDPlan"><a (click)="openCheckoutExisting('amz365u_premium')" target="_blank">Upgrade and Save<i
          class="material-icons">card_giftcard

        </i></a></li>

    <li *ngIf="!this.isUSDPlan"><a href="https://www.pyramidwifi.com/refer" target="_blank" target="_blank">Refer a
        friend (£10)<i class="material-icons">card_giftcard

        </i></a></li>

    <!-- <li
      *ngIf="userdata && !userdata.devAccount && userSubscription && (userSubscription.status == 'active' || userSubscription.status == 'cancelled')">
      <a [routerLink]="'/home/redeem'">Redeem Code<i class="material-icons">confirmation_number

        </i></a>
    </li> -->
    <!-- 
    <li *ngIf="userdata && !userdata.devAccount"><a [routerLink]="'/home/invite'">Invite <span class="new badge"
          data-badge-caption="Get Rewards"></span><i class="material-icons">favorite
        </i></a></li> -->

    <li>
      <div class="divider"></div>
    </li>

    <li><a href="https://www.pyramidwifi.com/settings" target="_blank">Manage Pyramid<i class="material-icons">wifi
        </i></a></li>

    <li><a href="https://www.pyramidwifi.com/hello" target="_blank">Help & Guides<i class="material-icons">live_help
        </i></a></li>
    <li
      *ngIf="userSubscription && (!(private_data && private_data.feedback_submitted) && (userSubscription.status == 'active' || userSubscription.status == 'in_trial') && (userSubscription.plan_id == 'pyramid-premium-monthly'))">
      <a (click)="sendFeedBack()" target="_blank">Share Feedback<i class="material-icons">feedback
        </i></a>
    </li>

    <li>
      <div class="divider"></div>
    </li>
    <li class="no-padding">
      <ul materialize="collapsible" class="collapsible collapsible-accordion">
        <li>
          <a class="collapsible-header waves-effect">Account Settings<i class="material-icons">account_circle
            </i></a>
          <div class="collapsible-body">
            <ul>
              <li *ngIf="userdata && !userdata.devAccount"><a class="waves-effect"
                  [routerLink]="'/home/mysubscription'">Update Plan</a></li>
              <li *ngIf="userdata && !userdata.devAccount"><a class="waves-effect"
                  (click)="openSelfServePortal()">Update Billing</a></li>
              <li><a class="waves-effect" [routerLink]="'/home/update-password'">Update Password</a></li>
              <li><a class="waves-effect" (click)="logout()">Sign Out</a></li>
            </ul>

          </div>
        </li>
      </ul>
    </li>

  </ul>
</nav>
<div class="fixed-action-btn">
  <a class="btn-floating btn-large teal accent-2 pulse" href="https://www.pyramidwifi.com/help" target="_blank">
    <i materialize="tooltip" class="large material-icons teal-text darken-4 tooltipped" data-position="top"
      data-delay="50" data-tooltip="Help"> help</i>
  </a>
</div>
<router-outlet></router-outlet>