<div id="test1" class="col s12">
  <div class="container" *ngIf="userdata && !userdata.devAccount">
    <div class="card">
      <div class="card-content">

        <ul class="collapsible" materialize="collapsible">
          <li class="active">
            <div class="collapsible-header active"><i class="material-icons">favorite</i>Invite</div>
            <div class="collapsible-body">


              <span>For every friend you recommend, you'll each get 30 days free Pyramid Premium Access. It gets applied
                automatically as soon as they activate. </span><br><br>
              <span>Your unique invite link is: </span><br>


              <h5 *ngIf="!userdata?.inviteCode">Click copy Invite to generate the code</h5>
              <h5 *ngIf="userdata?.inviteCode">pyramidwifi.com/invite?{{userdata.inviteCode}}</h5>

              <button class="waves-effect waves-light btn" (click)="copyInvite()">Copy Invite Link</button>


            </div>
          </li>


          <li>
            <div class="collapsible-header"><i class="material-icons">help
              </i>Have any questions?</div>
            <div class="collapsible-body"><span>If you have any questions about referring a friend, please reach out to
                us at <a href="mailto:help@pyramidwifi.com">help@pyramidwifi.com</a>.</span>
              <br><br>
            </div>
          </li>

        </ul>
      </div>

    </div>
  </div>
</div>