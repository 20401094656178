<div id="test1" class="col s12">
  <br />
  <div class="container">
    <div class="card">
      <div class="card-image">
        <img src="/assets/talk-to-us.png" />
      </div>
      <div class="card-content" align="center">
        <br />
        <p>
          If you have encountered a problem or technical issue, our friendly
          team are on-hand and would love the chance to fix it for you.
        </p>
      </div>
      <div align="center" class="card-action">
        <a class="waves-effect waves-light btn" target="_blank" href="https://www.pyramidwifi.com/support">TALK TO
          US</a>
      </div>
    </div>
    <div align="center">
      <p>
        if you don't help from our team you can instead
        <a routerLink="/home/cancel">continue to cancel</a>
      </p>
    </div>
  </div>
</div>