import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { HomeComponent } from "./home/home.component";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { AngularFirestoreModule, SETTINGS } from "@angular/fire/firestore";
import { HttpClientModule } from "@angular/common/http";
import { InvitedComponent } from "./invited/invited.component";
import { RegisterDevComponent } from "./register-dev/register-dev.component";
import { MySubscriptionComponent } from "./my-subscription/my-subscription.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RedeemCodeComponent } from "./redeem-code/redeem-code.component";
import { InviteComponent } from "./invite/invite.component";

// import "materialize-css";
import { MaterializeModule } from "angular2-materialize";
import { UpdatePasswordComponent } from "./update-password/update-password.component";
import { IndexComponent } from "./index/index.component";
import { AuthTokenHttpInterceptorProvider } from "./guard/auth-token.interceptor";
import { CancelComponent } from './cancel/cancel.component';
import { ActivationComponent } from './activation/activation.component';
import { SupportComponent } from './support/support.component';
import { PixelModule } from 'ngx-pixel';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ResetPasswordComponent,
    HomeComponent,
    InvitedComponent,
    RegisterDevComponent,
    MySubscriptionComponent,
    RedeemCodeComponent,
    InviteComponent,
    UpdatePasswordComponent,
    IndexComponent,
    CancelComponent,
    ActivationComponent,
    SupportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterializeModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    PixelModule.forRoot({ enabled: true, pixelId: '913032465499332' }),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
  ],
  providers: [
    AngularFireAuthGuard,
    AuthTokenHttpInterceptorProvider,
    // {
    //   provide: SETTINGS,
    //   useValue: environment.production
    //     ? undefined
    //     : {
    //         host: "localhost:8080",
    //         ssl: false,
    //       },
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
