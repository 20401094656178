import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-invited",
  templateUrl: "./invited.component.html",
  styleUrls: ["./invited.component.css"],
})
export class InvitedComponent implements OnInit {
  invitedForm: FormGroup;
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    private api: ApiService,
    private http: HttpClient,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.invitedForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ])
      ),
      password: new FormControl("", Validators.required),
      full_name: new FormControl("", Validators.required),
      inviteCode: new FormControl("", Validators.required),
    });
  }

  async onInvited(formData) {


    const response = await this.api
      .checkInviteCodeValidity(formData.inviteCode)
      .pipe(take(1))
      .toPromise();

    if (response["success"]) {
      const user = await this.api.fetchEmail(formData.email);
      if (user.length === 0) {
        formData.refererEmail = response["data"].email;
        this.openCheckout(formData);
      } else {
        this.api.showCustomAlertError(
          "There is already a user with this email!!!Use a different email address"
        );
      }
    } else {
      this.api.showCustomAlertError("Invitation Code invalid");
    }
  }

  openCheckout(userData) {
    this.api.cbInstance.openCheckout({
      hostedPage: () => {
        const data = {
          plan_id: "premium-plan",
          first_name: userData.full_name.split(" ")[0],
          last_name: userData.full_name.split(" ")[1] || "",
          email: userData.email,
          post_code: userData.post_code,
        };
        return this.http.post(`${this.api.apiurl}/checkout`, data).toPromise();
      },
      loaded: () => { },
      error: () => {
        this.ref.markForCheck();
      },
      close: () => {
        this.invitedForm.reset();
      },
      success: async (hostedPageId) => {
        const hostedPageData = await this.http
          .post(`${this.api.apiurl}/retriveHostedPage`, {
            hosted_page_id: hostedPageId,
          })
          .toPromise();

        const res = await this.api.register(userData.email, userData.password);
        try {
          await this.api
            .addToMailerLite(userData.refererEmail)
            .pipe(take(1))
            .toPromise();
        } catch (error) {
          console.log(`Unable to add referer email ${error.message}`);
        }

        delete userData.password;
        delete userData.inviteCode;
        delete userData.refererEmail;
        userData.customer_id = hostedPageData["content"]["customer"].id;
        userData.subscription_id = hostedPageData["content"]["subscription"].id;
        userData.subscription_date =
          hostedPageData["content"]["subscription"].created_at;
        userData.subscription_update_date =
          hostedPageData["content"]["subscription"].updated_at;
        userData.next_billing_at =
          hostedPageData["content"]["subscription"].next_billing_at;

        await this.api.saveProfile(res.user.uid, userData);

        window.location.href = "https://pyramidwifi.com/activated";
      },
      step: (value) => {
      },
    });
  }
}
