<div id="login-page" class="row">
  <div class="col s12 z-depth-6 card-panel">
    <!-- <form class="login-form"> -->
    <form class="login-form" [formGroup]="passwordForm" (ngSubmit)="onSubmit(passwordForm.value)">
      <div class="row">
      </div>
      <div class="row">

        <div align="center">
          <h4 align="center"> Forgot Password?</h4>
          <a align="center"> Please enter your email to get a reset link for your account
          </a>
        </div><br>
        <div class="input-field col s12">

          <i class="material-icons prefix">mail_outline</i>
          <input formControlName="email" class="validate" id="email" type="email">
          <label for="email">Email</label>
        </div>
      </div>


      <div class="row">
        <div class="input-field col s12">
          <button type="submit" [disabled]="!passwordForm.valid" class="btn waves-effect waves-light col s12">Email
            Me</button>
        </div>
      </div>
      <div class="row">


      </div>

    </form>
  </div>
  <div class="" align="center">
    <p class="margin center-align medium-small"><a class="white-text" routerLink='/login'>Go back</a></p>
  </div>
</div>