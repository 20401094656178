import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  constructor(public formBuilder: FormBuilder, private api: ApiService) {}

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ])
      ),
    });
  }

  async onSubmit(formData) {
    console.log(formData);

    try {
      await this.api.resetPassword(formData.email);
      this.passwordForm.reset();
      this.api.showCustomAlertSuccess("Reset Email sent, check your inbox");
    } catch (error) {
      this.api.showCustomAlertError(error.message);
    }
  }
}
