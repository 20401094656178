<div id="login-page" class="row bg">
  <p align="center"><a routerLink='/login'><img src="/assets/pyramid-logo.svg" /></a></p>
  <div class="col s12 z-depth-6 card-panel">
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
      <div class="row">
      </div>
      <div class="row">
        <div class="input-field col s12">
          <i class="material-icons prefix">mail_outline</i>
          <input formControlName="email" class="validate" id="email" type="email">
          <label for="email">Email</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <i class="material-icons prefix">lock_outline</i>
          <input formControlName="password" id="password" type="password">
          <label for="password">Password</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12">
          <button type="submit" [disabled]="!loginForm.valid" class="btn waves-effect waves-light col s12">Log
            In</button>
        </div>
      </div>
      <div class="row">


      </div>

    </form>
  </div>
  <div class="">
    <p class="margin right-align medium-small white-text"><a class="white-text" routerLink='/reset'>Forgot
        password?</a></p>
  </div>

  <!-- <div class="">
    <p class="margin right-align medium-small white-text"><a class="white-text" routerLink='/invited'>
        Invited</a></p>
  </div>

  <div class="">
    <p class="margin right-align medium-small white-text"><a class="white-text"
        routerLink='/register/test-plan2'>Register</a></p>
  </div>
  <div class="">
    <p class="margin right-align medium-small white-text"><a class="white-text" routerLink='/registerdev'>Register
        Dev</a>
    </p>
  </div> -->

</div>