import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { ApiService } from "../services/api.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/internal/operators/take";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  updatePasswordForm: FormGroup;
  plans = [];
  userdata;
  isUSDPlan = false
  loading: boolean;
  errMsg: boolean;
  registerForm: any;
  // router: any;
  userSubscription;
  currentPlan;
  showAllPlans = false;
  redeem_code;
  private_data
  userDataSubscription: Subscription;
  userPrivateDataSubscription: Subscription;
  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.updatePasswordForm = this.formBuilder.group({
      password: new FormControl("", Validators.compose([Validators.required])),
    });

    this.userDataSubscription = this.api
      .getUserDataFromFirbease(localStorage.getItem("useruuid"))
      .subscribe((data) => {
        this.userdata = data;
        if (this.userPrivateDataSubscription)
          this.userPrivateDataSubscription.unsubscribe()
        this.userPrivateDataSubscription = this.api.getUserPrivateData(localStorage.getItem("useruuid")).subscribe(p => {
          this.private_data = p
        })
        if (!this.userdata.devAccount) {
          this.api
            .getSubscritpion(this.userdata.subscription_id)
            .subscribe((subscription) => {
              this.userSubscription = subscription;
              this.isUSDPlan = this.api.isUSDPlan(this.userSubscription['plan_id'])
              this.api.saveProfile(localStorage.getItem("useruuid"), {
                plan_id: subscription["plan_id"],
                subscription_id: subscription["id"],
                subscription_date: subscription["created_at"],
                subscription_update_date: subscription["updated_at"],
                next_billing_at: subscription["next_billing_at"] || null,
                subscription_status: subscription["status"],
                cancelled_at: subscription["cancelled_at"] || null,
                pause_date: subscription["pause_date"] || null,
                resume_date: subscription["resume_date"] || null,
                billing_period_unit:
                  subscription["billing_period_unit"] || null,
              });
            });

          this.api.getPlans().subscribe((plans) => {
            const allPlans = plans["list"];
            // this.plans = plans["list"];
            this.currentPlan = allPlans.filter(
              (plan) => plan.plan.id === this.userdata.plan_id
            );

            this.plans = allPlans.filter(
              (plan) => plan.plan.price > this.currentPlan[0].plan.price
            );
          });
        }
      });
  }

  async updatePassword(password) {
    if (password.password != "") {
      try {
        (await this.api.getCurrentUser()).updatePassword(password.password);
        this.updatePasswordForm.reset();
        this.api.showCustomAlertSuccess("Password updated Successfully");
      } catch (error) {
        this.api.showCustomAlertError(error.message);
      }
    }
  }

  async logout() {
    await this.api.logout();
    localStorage.setItem("lastRoute", this.router.url)
    this.router.navigateByUrl("/login");
  }

  openSelfServePortal() {
    this.api.cbInstance.setPortalSession(() => {
      return this.http
        .post(`${this.api.apiurl}/portalSession`, {
          customer_id: this.userdata.customer_id,
        })
        .toPromise();
    });

    const cbPortal = this.api.cbInstance.createChargebeePortal();
    cbPortal.open({
      loaded: () => {
        // Optional
        // called when chargebee portal is loaded
      },
      close: () => {
        // Optional
        // called when chargebee portal is closed
      },
      visit: (data) => {
        // Optional
        // called whenever the customer navigates across different sections in portal
      },
      paymentSourceAdd: () => {
        // Optional
        // called whenever a new payment source is added in portal
      },
      paymentSourceUpdate: () => {
        // Optional
        // called whenever a payment source is updated in portal
      },
      paymentSourceRemove: () => {
        // Optional
        // called whenever a payment source is removed in portal.
      },
      subscriptionChanged: (data) => {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCustomFieldsChanged: (data) => {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: (data) => {
        // get subscription using the id and update the user accordingly
        this.showAllPlans = false;
        this.api.getSubscritpion(data.subscription.id).subscribe((result) => {
          this.api
            .saveProfile(localStorage.getItem("useruuid"), {
              plan_id: result["plan_id"],
              subscription_status: result["status"],
              cancelled_at: result["cancelled_at"],
            })
            .then(() => {
              this.api.showCustomAlertSuccess(
                "Subscription cancelled successfully"
              );
            });
        });

        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionPaused: (data) => {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionResumed: (data) => {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledPauseRemoved: (data) => {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionReactivated: (data) => {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
    });
  }


  sendFeedBack() {
    let url = 'https://pyramidwifi.typeform.com/to/'

    if (!this.private_data.feedback_submitted) {
      if (this.api.isUSDPlan(this.userSubscription["plan_id"]))
        window.open(`${url}GetMdyvw#email=${this.userdata.email}&name=${this.userdata.full_name.split(" ")[0]}`, '_blank');
      else
        window.open(`${url}o6JJzTuN#email=${this.userdata.email}&name=${this.userdata.full_name.split(" ")[0]}`, '_blank');

    }

  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.userPrivateDataSubscription) {
      this.userPrivateDataSubscription.unsubscribe();
    }
  }

  openCheckoutExisting(planid) {
    if (this.isUSDPlan) {
      planid = "usa_amz365u_premium_50";
    }
    this.api.cbInstance.openCheckout({
      hostedPage: () => {
        // this.loading = true;
        const data = {
          plan_id: planid,
          customer_id: this.userdata.customer_id,
        };
        return this.http
          .post(`${this.api.apiurl}/checkoutExisting`, data)
          .toPromise();
      },
      loaded: () => { },
      error: () => {
        // this.loading = false;
        // this.ref.markForCheck();
        // this.errMsg = true;
      },
      close: () => {
        // this.loading = false;
        // this.ref.detectChanges();
      },
      success: async (hostedPageId) => {
        let hostedPageData = await this.http
          .post(`${this.api.apiurl}/retriveHostedPage`, {
            hosted_page_id: hostedPageId,
          })
          .toPromise();

        // get previous plan next billing date and add 365 days to it. 


        const resp = await this.api.updateSubscritpion(hostedPageData["content"]["subscription"].id, hostedPageData["content"]["subscription"].plan_id, this.userSubscription.next_billing_at + 365 * 86400, 'active', 0).pipe(take(1)).toPromise();

        if (resp['success']) {

          this.api
            .saveProfile(localStorage.getItem("useruuid"), {
              plan_id: resp["data"].plan_id,
              subscription_id: resp
              ["data"].id,
              subscription_date:
                resp
                ["data"].created_at,
              subscription_update_date:
                resp
                ["data"].updated_at,
              next_billing_at:
                resp
                ["data"].next_billing_at,
              previous_plan_id: this.userdata.plan_id,
              subscription_status:
                resp
                ["data"].status,
            })
            .then(() => {
              this.api.showCustomAlertSuccess(
                "Subscription upgraded successfully"
              );
            });
        } else {
          this.api.showCustomAlertError(
            "An Error occurred, please contact support"
          );
        }

      },
      step: (value) => {
        // value -> which step in checkout
      },
    });
  }
}
