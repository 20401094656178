import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import swal from "sweetalert2";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PixelService } from "ngx-pixel";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiurl = environment.apiUrl;
  cbInstance;

  constructor(
    private afStore: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
    private pixel: PixelService
  ) {
    this.afAuth.authState.subscribe((res) => {
      if (res && res.uid) {
        localStorage.setItem("useruuid", res.uid);
        if (!this.router.url.includes("home")) {
          // if it is not home them navigate to home and user is logged in then navigate to home
          // check local storage for previous route and then navigate to that
          if (localStorage.getItem("lastRoute"))
            this.router.navigateByUrl(localStorage.getItem("lastRoute"));
          else this.router.navigateByUrl("/home");
        }
      } else {
        localStorage.removeItem("useruuid");
      }
    });

    this.cbInstance = window["Chargebee"].init({
      site: environment.site,
      publishableKey: environment.publishableKey,
      iframeOnly: true,
    });
  }

  showSuccessAlert() {
    swal.fire("Success", "Operation Successful", "success");
  }

  showErrorAlert() {
    swal.fire("Error!", "Operation Unsuccessful", "error");
  }

  showCustomAlertSuccess(message) {
    swal.fire("Success", message, "success");
  }
  showCustomAlertError(
    message,
    showCancelButton = false,
    confirmButtonText = "Ok"
  ) {
    return swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Close",
    });
  }

  showConfirmCacellation() {
    return swal.fire({
      title: "Please Confirm",
      text: "Are you sure you want to stop your subscription from renewing?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  }

  pauseSelection() {
    return swal.fire({
      title: "Pause period",
      text: "Please select how long would you like to pause the subscription ?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `1 Month`,
      denyButtonText: `3 Months`,
      cancelButtonText: "Cancel",
    });
  }

  showCancellationForYearlyPlan() {
    return swal.fire({
      title: "When do you want to turn off?",
      text: "You are on a Yearly Saver Pass, do you want to terminate your trial or turn off auto-renewal?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `End My Subscription Now`, // this is kept here because the effect of normal cancellation in trial is same as normal cancellation
      denyButtonText: `I still want my Yearly Pass (but keep renewal off)`,
      cancelButtonText: "Cancel",
      confirmButtonColor: '#d33',
      denyButtonColor: '#3085d6',
    });
  }

  getCurrentUser() {
    return this.afAuth.auth.currentUser;
  }

  getUserDataFromFirbease(id) {
    return this.doc$(`users/${id}`);
  }

  getUserPrivateData(id) {
    return this.doc$(`users/${id}/private/private_data`);
  }

  register(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }

  login(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  fetchEmail(email) {
    return this.afAuth.auth.fetchSignInMethodsForEmail(email);
  }

  resetPassword(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  saveProfile(id, user) {
    return this.updateAt(`users/${id}/`, user);
  }

  getPlans() {
    return this.http.get(`${this.apiurl}/plans`);
  }

  getSubscritpion(id) {
    return this.http.get(`${this.apiurl}/subscription?id=${id}`);
  }

  checkInviteCodeValidity(inviteCode) {
    return this.http.get(
      `${this.apiurl}/invitationValidity?inviteCode=${inviteCode}`
    );
  }

  addToMailerLite(email) {
    return this.http.get(`${this.apiurl}/addToMailerLite?email=${email}`);
  }

  cancelSubscription(id, flag = false) {
    return this.http.get(`${this.apiurl}/cancelSubscription?id=${id}&cancelFlag=${flag}`);
  }

  scheduledCancelSubscription(id) {
    return this.http.get(`${this.apiurl}/scheduledCancelSubscription?id=${id}`);
  }
  reactivateSubscription(id) {
    return this.http.get(`${this.apiurl}/reactivateSubscription?id=${id}`);
  }
  removeScheduledPause(id) {
    return this.http.get(`${this.apiurl}/removeScheduledPause?id=${id}`);
  }

  checkCustomer(email) {
    return this.http.get(`${this.apiurl}/customer?email=${email}`);
  }

  getPromoCode(code) {
    return this.http.get(`${this.apiurl}/getPromoCode?promo_code=${code}`);
  }

  getPlanCode(code) {
    return this.http.get(`${this.apiurl}/getPlan?promo_code=${code}`);
  }

  pauseSubscritpion(id, resume) {
    return this.http.post(`${this.apiurl}/pauseSubscription`, {
      subscription_id: id,
      resume_date: resume,
    });
  }

  updateSubscritpion(id, plan_id, current_term_end, status, days) {
    return this.http.post(`${this.apiurl}/updateSubscription`, {
      subscription_id: id,
      plan_id: plan_id,
      current_term_end: current_term_end,
      status: status,
      days: days,
    });
  }


  doc$(path): Observable<any> {
    return this.afStore
      .doc(path)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          return { id: doc.payload.id, ...(doc.payload.data() as any) };
        })
      );
  }

  updateAt(path: string, data: object): Promise<any> {
    const segments = path.split("/").filter((v) => v);
    if (segments.length % 2) {
      // Odd is always a collection
      return this.afStore.collection(path).add(data);
    } else {
      // Even is always document
      // { merge: true } will create the document if it doent exist and will update the doc if it does
      return this.afStore.doc(path).set(data, { merge: true });
    }
  }

  formatDateForTypeForm(unixDate) {
    let date = new Date(+(unixDate + "000")).toDateString();
    let splitDate = date.split(" ");

    return (
      splitDate[2] +
      splitDate[1].toLocaleUpperCase() +
      splitDate[3].split("0")[1]
    );
  }

  isUSDPlan(planId: string) {
    return planId.toUpperCase().includes("USA");
  }

  async getSelectedPlanName(plan_id) {
    let allPlans = await this.getPlans().pipe(take(1)).toPromise();
    return allPlans['list'].find(plan => plan.plan.id == plan_id);
  }

  async executePixelEvent(plan_id) {

    let plan = await this.getSelectedPlanName(plan_id);
    if (plan.plan) {
      plan = plan.plan;
      this.pixel.track('InitiateCheckout', {
        content_ids: [plan.name],
        value: plan.period_unit == 'year' ? plan.currency_code == "USD" ? 69 : 49 : 6.99,
        currency: plan.currency_code
      });
    }
  }

  isLifetimeUser(email) {
    const lifeTimeUsers = [
      "Leo.groenewegen@cellularevolution.co.uk",
      "2spam@gmx.at",
      "Collymore@mac.com",
      "abrown@kolaghassicapital.com",
      "alanto2002@yahoo.co.uk",
      "al.ritchie@icloud.com",
      "adamscrawford@outlook.com",
      "alanb61@yahoo.co.uk",
      "allan_anandappa1@hotmail.com",
      "amandaoliver506@gmail.com",
      "ant_england@msn.com",
      "amer.asim@gmail.com",
      "andy@viewtvabroad.com",
      "atom@pyramidwifi.com",
      "artnoonan@blueyonder.co.uk",
      "beado187@gmail.com",
      "chrisatsaydo@gmail.com",
      "b.abramowicz@outlook.com",
      "briandshar@icloud.com",
      "colairo10@gmail.com",
      "christina.foerster@outlook.com",
      "craig.fisher001@gmail.com",
      "contact@techloto.com",
      "colkav22@gmail.com",
      "craig.duggan@yahoo.co.uk",
      "danccfc@hotmail.com",
      "david55tong@gmail.com",
      "dave.j.gray@outlook.com",
      "declan2.2clark@hotmail.com",
      "depakbali@hotmail.com",
      "dennis.birkett@gmail.com",
      "dipakrana@aol.com",
      "dj.harvey1989@gmail.com",
      "divieshm@gmail.com",
      "ed@sml.my",
      "esuarez@ntlworld.com",
      "eddie.janson@yahoo.com",
      "ekinscj@hotmail.co.uk",
      "gail.joughin@googlemail.com",
      "fwearle@hotmail.com",
      "greg182@gmail.com",
      "greg228@gmail.com",
      "goonermel@gmail.com1",
      "grewalrajbir@gmail.com",
      "helen@d1hel.com",
      "hucky_n4c@hotmail.com",
      "hodkinsonian34@yahoo.com",
      "happy.al@outlook.com",
      "info@scottishedge.com",
      "ab014e2054@blueyonder.co.uk",
      "Hasselstrom1@gmail.com",
      "jack.willis73@yahoo.com",
      "jack.shawcross@yahoo.com",
      "jack-njappi@hotmail.com",
      "jack9299@gmail.com",
      "jason.livingstone1@hotmail.com",
      "jimflood86@gmail.com",
      "jeanblack56@gmail.com",
      "jbaldwin@email.lu",
      "john.kender@wanadoo.fr",
      "jimsmith@gmail.com",
      "jon.howson@live.co.uk",
      "johnasiko@yahoo.com",
      "john87130@aol.com",
      "k.toohey6@blueyonder.co.uk",
      "karen@rawfoodcoach.com",
      "kevin.rooney5@virginmedia.com",
      "kashernene@aol.com",
      "keithdella@ymail.com",
      "maltaseafront@gmail.com",
      "markzwinderman@gmail.com",
      "martina.miotto@cellularevolution.co.uk",
      "matt@belmont-ndt.co.uk",
      "mattyayres@gmail.com",
      "mcgregor.john02@googlemail.com",
      "mikemensah@btinternet.com",
      "mchallinor@hotmail.co.uk-delete",
      "mncassford@hotmail.co.uk",
      "mpickering@ntlworld.com",
      "mufc99@gmail.com",
      "neilpoyton@yahoo.co.uk",
      "office@dlcevents.com",
      "opsyd2010@yahoo.co.uk",
      "orthmankhamra@gmail.com",
      "paul@thornbank.org",
      "petersnewscotland@gmail.com",
      "philbr7@live.co.uk1",
      "pleasework@gmail.com",
      "pr.field@yahoo.com",
      "pyramidwifi@gmail.co.uk",
      "retired-efoy1@eircom.net",
      "rob@ehr.scot",
      "rowlands2020@hotmail.com",
      "ryanthomson72@icloud.com",
      "s3v3nup@hotmail.com",
      "sarahmullins@eircom.net",
      "safleurlondon@gmail.com",
      "scottheaneytest@gmail.com",
      "sina.sabagh@gmail.com",
      "sean@latitude-it.net",
      "sinaiceboy@gmail.com",
      "stephanierubin59@gmail.com",
      "marco.borzoni@sky.com",
      "stephenhopper9554@gmail.co.uk",
      "steve@craftprospect.com",
      "stephenmciver1968@gmail.com",
      "stevenmcoates@googlemail.com",
      "support@thelinkedingurus.co.uk",
      "test@pyramidwifi.com",
      "traceypatterson@rocketmail.com",
      "the.pillings@btinternet.com",
      "vic.smith2@btinternet.com",
      "urpo.karjalainen@live.com",
      "yasar_1311@msn.com",
      "info@justbarking.co.uk",
      "robheather.kelly@gmail.com",
      "siobhan_thomas92@hotmail.com",
      "muhammad-sheraz.alvi@britishcouncil.org",
      "manorhouseoffice-former@gmail.com"]

    return lifeTimeUsers.includes(email)
  }
}
