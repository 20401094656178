import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { ApiService } from "../services/api.service";

@Component({
  selector: "app-my-subscription",
  templateUrl: "./my-subscription.component.html",
  styleUrls: ["./my-subscription.component.css"],
})
export class MySubscriptionComponent implements OnInit, OnDestroy {
  plans = [];
  userdata;
  loading: boolean;
  errMsg: boolean;
  registerForm: any;
  userDataSubscription: Subscription;
  userSubscription;
  currentPlan;
  showPause = true;
  redeem_code;
  userPlan;
  isUSDPlan: boolean = false;
  constructor(
    public api: ApiService,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.userDataSubscription = this.api
      .getUserDataFromFirbease(localStorage.getItem("useruuid"))
      .subscribe((data) => {
        this.userdata = data;
        if (!this.userdata.devAccount) {
          this.api
            .getSubscritpion(this.userdata.subscription_id)
            .subscribe((subscription) => {
              // console.log(subscription)
              // check here if difference of current date and next billing cycle is more than 90 days for yearly plan
              // then hide pause button
              let currentDate = new Date().getTime() / 1000;
              if (
                subscription["next_billing_at"] - currentDate > 90 * 86400 &&
                subscription["billing_period_unit"] === "year"
              ) {
                this.showPause = false;
              }

              this.userSubscription = subscription;
              this.isUSDPlan = this.api.isUSDPlan(
                this.userSubscription["plan_id"]
              );
              this.api.saveProfile(localStorage.getItem("useruuid"), {
                plan_id: subscription["plan_id"],
                subscription_id: subscription["id"],
                subscription_date: subscription["created_at"],
                subscription_update_date: subscription["updated_at"],
                next_billing_at: subscription["next_billing_at"] || null,
                subscription_status: subscription["status"],
                cancelled_at: subscription["cancelled_at"] || null,
                pause_date: subscription["pause_date"] || null,
                resume_date: subscription["resume_date"] || null,
              });

              this.api.getPlans().subscribe((plans) => {
                // console.log(plans['list'])
                // console.log(this.userSubscription.plan_id)
                this.userPlan = plans["list"].filter(
                  (x) => x.plan.id == this.userSubscription.plan_id
                )[0];
                const allPlans = plans["list"];
                if (subscription["status"] == "cancelled") {
                  if (!this.isUSDPlan)
                    this.plans = allPlans.filter(
                      (plan) =>
                        plan.plan.id !== this.userdata.plan_id &&
                        !this.api.isUSDPlan(plan.plan.id) &&
                        (plan.plan.id.slice(plan.plan.id.length - 1) == "u" ||
                          plan.plan.id.slice(plan.plan.id.length - 1) == "r")
                    );
                  else {
                    this.plans = allPlans.filter(
                      (plan) =>
                        plan.plan.id !== this.userdata.plan_id &&
                        this.api.isUSDPlan(plan.plan.id) &&
                        (plan.plan.id.slice(plan.plan.id.length - 1) == "u" ||
                          plan.plan.id.slice(plan.plan.id.length - 1) == "r")
                    );
                  }
                } else {
                  this.plans = allPlans.filter(
                    (plan) =>
                      plan.plan.id !== this.userdata.plan_id &&
                      plan.plan.id.slice(plan.plan.id.length - 1) == "u"
                  );
                }
              });
            });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  reactivate(id) {
    this.api.reactivateSubscription(id).subscribe((result) => {
      this.api
        .saveProfile(localStorage.getItem("useruuid"), {
          plan_id: result["plan_id"],
          subscription_id: result["id"],
          subscription_date: result["created_at"],
          subscription_update_date: result["updated_at"],
          next_billing_at: result["next_billing_at"] || null,
          subscription_status: result["status"],
          cancelled_at: result["cancelled_at"] || null,
        })
        .then(() => {
          this.api.showCustomAlertSuccess(
            "Subscription reactivated successfully"
          );
        });
    });
  }

  async cancel(id) {
    this.router.navigateByUrl("home/support");
  }

  async pause(id) {
    const result = await this.api.pauseSelection();

    if (result.isConfirmed) {
      this.pauseSubscription(id, 30);
    } else if (result.isDenied) {
      this.pauseSubscription(id, 90);
    }
  }

  openSelfServePortal() {
    this.api.cbInstance.setPortalSession(() => {
      return this.http
        .post(`${this.api.apiurl}/portalSession`, {
          customer_id: this.userdata.customer_id,
        })
        .toPromise();
    });

    const cbPortal = this.api.cbInstance.createChargebeePortal();
    cbPortal.open({
      loaded: () => {
        // Optional
        // called when chargebee portal is loaded
      },
      close: () => {
        // Optional
        // called when chargebee portal is closed
      },
      visit: () => {
        // Optional
        // called whenever the customer navigates across different sections in portal
      },
      paymentSourceAdd: () => {
        // Optional
        // called whenever a new payment source is added in portal
      },
      paymentSourceUpdate: () => {
        // Optional
        // called whenever a payment source is updated in portal
      },
      paymentSourceRemove: () => {
        // Optional
        // called whenever a payment source is removed in portal.
      },
      subscriptionChanged: () => {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCustomFieldsChanged: () => {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: (data) => {
        // get subscription using the id and update the user accordingly

        this.api.getSubscritpion(data.subscription.id).subscribe((result) => {
          this.api
            .saveProfile(localStorage.getItem("useruuid"), {
              plan_id: result["plan_id"],
              subscription_status: result["status"],
              cancelled_at: result["cancelled_at"],
            })
            .then(() => {
              this.api.showCustomAlertSuccess(
                "Subscription cancelled successfully"
              );
            });
        });

        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionPaused: () => {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionResumed: () => {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledPauseRemoved: () => {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionReactivated: () => {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
    });
  }

  openCheckoutExisting(planid) {
    this.api.cbInstance.openCheckout({
      hostedPage: () => {
        // this.loading = true;
        const data = {
          plan_id: planid,
          customer_id: this.userdata.customer_id,
        };
        return this.http
          .post(`${this.api.apiurl}/checkoutExisting`, data)
          .toPromise();
      },
      loaded: () => { },
      error: () => {
        // this.loading = false;
        // this.ref.markForCheck();
        // this.errMsg = true;
      },
      close: () => {
        // this.loading = false;
        // this.ref.detectChanges();
      },
      success: async (hostedPageId) => {
        let hostedPageData = await this.http
          .post(`${this.api.apiurl}/retriveHostedPage`, {
            hosted_page_id: hostedPageId,
          })
          .toPromise();

        this.api
          .saveProfile(localStorage.getItem("useruuid"), {
            plan_id: hostedPageData["content"]["subscription"].plan_id,
            subscription_id: hostedPageData["content"]["subscription"].id,
            subscription_date:
              hostedPageData["content"]["subscription"].created_at,
            subscription_update_date:
              hostedPageData["content"]["subscription"].updated_at,
            next_billing_at:
              hostedPageData["content"]["subscription"].next_billing_at,
            previous_plan_id: this.userdata.plan_id,
            subscription_status:
              hostedPageData["content"]["subscription"].status,
          })
          .then(() => {
            this.api.showCustomAlertSuccess(
              "Subscription upgraded successfully"
            );
          });
      },
      step: () => {
        // value -> which step in checkout
      },
    });
  }

  pauseSubscription(id, days) {
    this.api
      .pauseSubscritpion(
        id,
        this.userSubscription.current_term_end + days * 86400
      )
      .subscribe((result) => {
        this.api
          .saveProfile(localStorage.getItem("useruuid"), {
            plan_id: result["plan_id"],
            subscription_status: result["status"],
            pause_date: result["pause_date"] || null,
            resume_date: result["resume_date"] || null,
          })
          .then(() => {
            this.api.showCustomAlertSuccess("Subscription paused successfully");
          });
      });
  }

  removeScheduledPause(id) {
    this.api.removeScheduledPause(id).subscribe(result => {
      if (result['success'])
        this.api.showCustomAlertSuccess("Scheduled pause removed successfully");
      else
        this.api.showCustomAlertError("Unable to remove schedule pause removed successfully");
    })
  }
}
