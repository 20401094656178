import { Component, OnInit, NgZone, ChangeDetectorRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  plan_id;
  private sub: Subscription;
  activateClicked = false;
  isUsdPlan = true;
  states = [
    "Alaska",
    "Alabama",
    "Arkansas",
    "American Samoa",
    "Arizona",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Iowa",
    "Idaho",
    "Illinois",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Maine",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "North Carolina",
    " North Dakota",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "Nevada",
    "New York",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Virginia",
    "Virgin Islands",
    "Vermont",
    "Washington",
    "Wisconsin",
    "West Virginia",
    "Wyoming",
  ];

  states_hash =
    {
      'Alabama': 'AL',
      'Alaska': 'AK',
      'American Samoa': 'AS',
      'Arizona': 'AZ',
      'Arkansas': 'AR',
      'California': 'CA',
      'Colorado': 'CO',
      'Connecticut': 'CT',
      'Delaware': 'DE',
      'District Of Columbia': 'DC',
      'Federated States Of Micronesia': 'FM',
      'Florida': 'FL',
      'Georgia': 'GA',
      'Guam': 'GU',
      'Hawaii': 'HI',
      'Idaho': 'ID',
      'Illinois': 'IL',
      'Indiana': 'IN',
      'Iowa': 'IA',
      'Kansas': 'KS',
      'Kentucky': 'KY',
      'Louisiana': 'LA',
      'Maine': 'ME',
      'Marshall Islands': 'MH',
      'Maryland': 'MD',
      'Massachusetts': 'MA',
      'Michigan': 'MI',
      'Minnesota': 'MN',
      'Mississippi': 'MS',
      'Missouri': 'MO',
      'Montana': 'MT',
      'Nebraska': 'NE',
      'Nevada': 'NV',
      'New Hampshire': 'NH',
      'New Jersey': 'NJ',
      'New Mexico': 'NM',
      'New York': 'NY',
      'North Carolina': 'NC',
      'North Dakota': 'ND',
      'Northern Mariana Islands': 'MP',
      'Ohio': 'OH',
      'Oklahoma': 'OK',
      'Oregon': 'OR',
      'Palau': 'PW',
      'Pennsylvania': 'PA',
      'Puerto Rico': 'PR',
      'Rhode Island': 'RI',
      'South Carolina': 'SC',
      'South Dakota': 'SD',
      'Tennessee': 'TN',
      'Texas': 'TX',
      'Utah': 'UT',
      'Vermont': 'VT',
      'Virgin Islands': 'VI',
      'Virginia': 'VA',
      'Washington': 'WA',
      'West Virginia': 'WV',
      'Wisconsin': 'WI',
      'Wyoming': 'WY'
    }
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    private api: ApiService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.plan_id = params["plan_id"];
      if (this.plan_id === "premium-plan") {
        this.router.navigateByUrl("login");
      }
      this.isUsdPlan = this.api.isUSDPlan(this.plan_id);
      this.registerForm = this.formBuilder.group({
        email: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
          ])
        ),
        password: new FormControl("", Validators.required),
        full_name: new FormControl("", Validators.required),
        post_code: new FormControl("", Validators.required),
        // plan_id: new FormControl("", Validators.required),

      });
      if (this.isUsdPlan)
        this.registerForm.addControl('state', new FormControl('Alaska', Validators.required));
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async onRegister(formData) {
    this.activateClicked = true;
    try {
      const user = await this.api.fetchEmail(formData.email);
      const customer = await this.api.checkCustomer(formData.email).toPromise();
      if (
        user.length === 0 &&
        (!customer["success"] ||
          (customer["success"] &&
            customer["data"]["content"]["subscription"].status == "cancelled"))
      ) {
        try {
          await this.api.executePixelEvent(this.plan_id);
        } catch (error) {
          alert("PIXEL ERROR" + error.message)
          console.log(error)
        }
        this.openCheckout(formData);
      } else {
        if (customer["success"]) {
          // TODO: make so that if user has subscription data in db delete that.
          // assign user his subscription data and save profile
          const res = await this.api.register(
            formData.email,
            formData.password
          );

          let hostedPageData = customer["data"];
          let userData: any = {};
          userData.customer_id = hostedPageData["content"]["customer"].id;
          userData.subscription_id =
            hostedPageData["content"]["subscription"].id;
          userData.subscription_date =
            hostedPageData["content"]["subscription"].created_at;
          userData.subscription_update_date =
            hostedPageData["content"]["subscription"].updated_at;
          userData.next_billing_at =
            hostedPageData["content"]["subscription"].next_billing_at;
          userData.billing_period_unit =
            hostedPageData["content"]["subscription"].billing_period_unit;
          userData.subscription_status =
            hostedPageData["content"]["subscription"].status;
          userData.plan_id = hostedPageData["content"]["subscription"].plan_id;
          userData.cancelled_at = null;
          userData.pause_date = null;
          userData.resume_date = null;

          userData.email = formData.email;
          userData.full_name = formData.full_name;
          userData.post_code = formData.post_code;

          userData.state = formData.state ? formData.state : null
          userData.country = formData.state ? "US" : null;

          userData.inviteCode =
            formData.full_name.split(" ")[0] +
            Math.random().toString(36).substring(2, 7).toUpperCase();

          await this.api.saveProfile(res.user.uid, userData);
          window.location.href = "https://pyramidwifi.com/activated";
        } else {
          const result = await this.api.showCustomAlertError(
            "This account already exists. SignIn instead ?",
            true,
            "SignIn"
          );
          if (result.value) {
            this.router.navigateByUrl("login");
          }
        }
      }
    } catch (error) {
      this.activateClicked = false;
      this.api.showCustomAlertError(error.message);
    }
  }

  openCheckout(userData) {
    this.api.cbInstance.openCheckout({
      hostedPage: () => {
        const data = {
          plan_id: this.plan_id,
          first_name: userData.full_name.split(" ")[0],
          last_name: userData.full_name.split(" ")[1] || "",
          email: userData.email,
          post_code: userData.post_code,
          state: userData.state ? userData.state : null,
          state_code: this.states_hash[userData.state] ? this.states_hash[userData.state] : null,
          country: userData.state ? "US" : null,
        };
        return this.http.post(`${this.api.apiurl}/checkout`, data).toPromise();
      },
      loaded: () => { },
      error: () => {
        this.ref.markForCheck();
      },
      close: () => {
        this.registerForm.reset();
      },
      success: async (hostedPageId) => {
        const hostedPageData = await this.http
          .post(`${this.api.apiurl}/retriveHostedPage`, {
            hosted_page_id: hostedPageId,
          })
          .toPromise();

        const res = await this.api.register(userData.email, userData.password);

        delete userData.password;
        userData.customer_id = hostedPageData["content"]["customer"].id;
        userData.subscription_id = hostedPageData["content"]["subscription"].id;
        userData.subscription_date =
          hostedPageData["content"]["subscription"].created_at;
        userData.subscription_update_date =
          hostedPageData["content"]["subscription"].updated_at;
        userData.next_billing_at =
          hostedPageData["content"]["subscription"].next_billing_at;
        userData.billing_period_unit =
          hostedPageData["content"]["subscription"].billing_period_unit;
        userData.subscription_status =
          hostedPageData["content"]["subscription"].status;
        userData.plan_id = hostedPageData["content"]["subscription"].plan_id;
        userData.cancelled_at = null;
        userData.pause_date = null;
        userData.resume_date = null;

        userData.inviteCode =
          userData.full_name.split(" ")[0] +
          Math.random().toString(36).substring(2, 7).toUpperCase();
        await this.api.saveProfile(res.user.uid, userData);

        window.location.href = "https://pyramidwifi.com/activated";
      },
      step: (value) => { },
    });
  }
}
