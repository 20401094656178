import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {
  userDataSubscription: Subscription;
  userSubscription;
  userdata
  showPause = true
  constructor(private router: Router, private api: ApiService) { }

  ngOnInit() {

    this.userDataSubscription = this.api
      .getUserDataFromFirbease(localStorage.getItem("useruuid"))
      .subscribe((data) => {
        this.userdata = data;
        if (!this.userdata.devAccount) {
          this.api
            .getSubscritpion(this.userdata.subscription_id)
            .subscribe((subscription) => {
              let currentDate = new Date().getTime() / 1000;
              if (
                subscription["next_billing_at"] - currentDate > 90 * 86400 &&
                subscription["billing_period_unit"] === "year"
              ) {
                this.showPause = false;
              }
              this.userSubscription = subscription;
              this.api.saveProfile(localStorage.getItem("useruuid"), {
                plan_id: subscription["plan_id"],
                subscription_id: subscription["id"],
                subscription_date: subscription["created_at"],
                subscription_update_date: subscription["updated_at"],
                next_billing_at: subscription["next_billing_at"] || null,
                subscription_status: subscription["status"],
                cancelled_at: subscription["cancelled_at"] || null,
                pause_date: subscription["pause_date"] || null,
                resume_date: subscription["resume_date"] || null,
              });

            });

        }
      });
  }


  reminder() {

    if (this.userdata.reminder_email) {
      this.api.showCustomAlertSuccess("Reminder Setup successfully");
      return
    }


    this.api
      .saveProfile(localStorage.getItem("useruuid"), {
        reminder_email: true
      })
      .then(() => {
        this.api.showCustomAlertSuccess("Reminder Setup successfully");
      });

  }

  async cancel(id) {
    let result
    let yearlyPlan = false;
    if (this.userdata.plan_id == 'switch-and-save-yearly-premium' && this.userdata.subscription_status == 'in_trial') {
      result = await this.api.showCancellationForYearlyPlan();
      yearlyPlan = true;
    } else
      result = await this.api.showConfirmCacellation();
    if (result.isConfirmed) {
      this.api.cancelSubscription(id).subscribe((result) => {
        this.api
          .saveProfile(localStorage.getItem("useruuid"), {
            plan_id: result["plan_id"],
            subscription_status: result["status"],
            cancelled_at: result["cancelled_at"],
          })
          .then(() => {
            this.api.showCustomAlertSuccess(
              "Subscription cancelled successfully"
            );
            this.router.navigateByUrl("home/index");
            const activeDate = this.api.formatDateForTypeForm(this.userSubscription["created_at"])

            if (this.api.isUSDPlan(this.userSubscription["plan_id"]))
              window.open(`https://pyramidwifi.typeform.com/to/vmZMkMp1?name=${this.userdata.full_name.split(" ")[0]}&email=${this.userdata.email}&active=${activeDate}`, '_blank');
            else
              window.open(`https://pyramidwifi.typeform.com/to/XmVeYBDA?name=${this.userdata.full_name.split(" ")[0]}&email=${this.userdata.email}&active=${activeDate}`, '_blank');

          });
      });
    } else if (result.isDenied) {

      this.api
        .saveProfile(localStorage.getItem("useruuid"), {
          auto_renew_off: true
        })
        .then(() => {
          this.api.showCustomAlertSuccess(
            "Auto renewal has been turned off following your yearly pass"
          );
          this.router.navigateByUrl("home/mysubscription");

        });


    }
  }


  async pause(id) {
    const result = await this.api.pauseSelection();
    if (result.isConfirmed) {
      this.pauseSubscription(id, 30);
    } else if (result.isDenied) {
      this.pauseSubscription(id, 90);
    }
  }

  pauseSubscription(id, days) {
    this.api
      .pauseSubscritpion(
        id,
        this.userSubscription.current_term_end + days * 86400
      )
      .subscribe((result) => {
        this.api
          .saveProfile(localStorage.getItem("useruuid"), {
            plan_id: result["plan_id"],
            subscription_status: result["status"],
            pause_date: result["pause_date"] || null,
            resume_date: result["resume_date"] || null,
          })
          .then(() => {
            this.api.showCustomAlertSuccess("Subscription paused successfully");
          });
      });
  }

  toHome() {
    this.router.navigateByUrl("home/index");
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
  }


}
