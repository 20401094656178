import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-register-dev",
  templateUrl: "./register-dev.component.html",
  styleUrls: ["./register-dev.component.css"],
})
export class RegisterDevComponent implements OnInit {
  registerForm: FormGroup;
  // plans;
  plan_id;
  private sub: Subscription;
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    private api: ApiService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.plan_id = params["plan_id"];
      if (this.plan_id === "premium-plan") {
        this.router.navigateByUrl("login");
      }
    });
    this.registerForm = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ])
      ),
      password: new FormControl("", Validators.required),
      full_name: new FormControl("", Validators.required),
      post_code: new FormControl("", Validators.required),
      // plan_id: new FormControl("", Validators.required),
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async onRegister(userData) {
    try {
      const user = await this.api.fetchEmail(userData.email);

      if (user.length !== 0) {
        this.api.showCustomAlertError(
          "There is already a user with this email!!!Use a different email address"
        );
      } else {
        // save user profile
        const res = await this.api.register(userData.email, userData.password);

        delete userData.password;
        userData.devAccount = true;

        await this.api.saveProfile(res.user.uid, userData);
      }
    } catch (error) {
      this.api.showCustomAlertError(error.message);
    }
  }
}
