<div id="test1" class="col s12">
  <div class="container">
    <div class="card">
      <div class="card-content">

        <ul class="collapsible" materialize="collapsible">
          <li class="active">
            <div class="collapsible-header active"><i class="material-icons">lock
              </i>Update Password
            </div>
            <div class="collapsible-body">

              <p> <b> Update Notification</b><br><br>
                If you are running an old version of Pyramid firmware, v2.0 or earlier,
                please reset your password by <a href="https://www.pyramidgo.com/pay/account/resetrequest.php"
                  target="_blank">tapping here</a>.<br><br>
                Alternatively, you can reset your password below but be sure to <a
                  href="https://www.pyramidwifi.com/update" target="_blank">update
                  your Pyramid</a> to the latest version (recommended).
              </p>
              <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword(updatePasswordForm.value)">
                <div class="input-field col s12">
                  <input formControlName="password" id="password" type="text">
                  <label for="password">New Password</label>

                </div>
                <button class="waves-effect waves-light btn" type="submit" [disabled]="!updatePasswordForm.valid">Update
                  Password</button>

              </form>
            </div>
          </li>

          <li>
            <div class="collapsible-header"><i class="material-icons">wifi</i>Looking to update WiFi or
              Pyramid Admin Password?</div>
            <div class="collapsible-body"><span>If would like to update the WiFi Password of Pyramid,
                simply <a href="https://www.pyramidwifi.com/settings" target="_blank">sign in</a>
                and then select <i>Network Settings > Update WiFi Name / Password</i> from the
                menu.</span>
              <br><br>
              <span>If you can't remember the admin password or the WiFi password in order connect,
                then tap and hold the reset button on the side of Pyramid for 10 - 15 seconds until
                the right hand light flashes rapidly then release your finger. After 2 - 3 minutes
                you should be able to connect using the <a href="https://pyramidwifi.co/app/connect.html"
                  target="blank">default
                  password</a>. </span>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>