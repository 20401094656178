<div id="login-page" class="row">
  <p align="center">
    <a routerLink="/login"> <img src="/assets/pyramid-logo.svg" /></a>
  </p>

  <!-- <div *ngIf="plan_id == 'pyramid30d_usa'">
    <p></p>
    <div _ngcontent-rrt-c1="" align="left" class="col s4">
      <a _ngcontent-rrt-c1="" routerlink="/login" href="/login"><img _ngcontent-rrt-c1=""
          src="/assets/pyramid-logo.svg" /></a>
    </div>
    <div _ngcontent-rrt-c1="" align="right" class="col s8">
      <h4 class="white-text text-lighten-1" align="">30 Days Free</h4>
      <span class="purple-text text-lighten-4" align="">Then $8.99/month. Cancel anytime.</span>
    </div>
  </div> -->

  <div class="col s12 z-depth-6 card-panel">
    <form class="login-form" [formGroup]="registerForm" (ngSubmit)="onRegister(registerForm.value)">
      <div class="row">
        <br />
        <div class="input-field col s12">
          <input formControlName="full_name" id="name" type="text" class="validate" />
          <label for="name">Full Name</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input formControlName="email" id="email" type="email" class="validate" />
          <label for="email">Email</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input formControlName="password" id="password" type="password" class="validate" />
          <label for="password">Password</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s4">
          <input formControlName="post_code" id="postcode" type="text" class="validate" />
          <label for="postcode">Post/Zip Code</label>
        </div>
        <div class="input-field col s8" *ngIf="isUsdPlan">
          <select formControlName="state" materialize="material_select" [materializeSelectOptions]="states">
            >
            <option *ngFor="let state of states" [value]="state">
              {{ state }}
            </option>
          </select>
          <label>State</label>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12">
          <button *ngIf="!activateClicked" type="submit" [disabled]="!registerForm.valid"
            class="btn waves-effect waves-light col s12">
            Activate
          </button>
          <button *ngIf="activateClicked" disabled="true" class="btn waves-effect waves-light col s12">
            Please wait...
          </button>
        </div>
      </div>
      <div class="row"></div>
    </form>
  </div>
</div>