import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { ApiService } from "../services/api.service";
// import { arrayUnion } from '@angular/fire/firestore'
import * as firebase from "firebase";
import { Subscription } from "rxjs";

@Component({
  selector: "app-redeem-code",
  templateUrl: "./redeem-code.component.html",
  styleUrls: ["./redeem-code.component.css"],
})
export class RedeemCodeComponent implements OnInit {
  redeem_code;
  userdata;
  userSubscription;
  userDataSubscription: Subscription;
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.userDataSubscription = this.api
      .getUserDataFromFirbease(localStorage.getItem("useruuid"))
      .subscribe((data) => {
        this.userdata = data;

        if (!this.userdata.devAccount) {
          this.api
            .getSubscritpion(this.userdata.subscription_id)
            .subscribe((subscription) => {
              this.userSubscription = subscription;
              this.api.saveProfile(localStorage.getItem("useruuid"), {
                plan_id: subscription["plan_id"],
                subscription_id: subscription["id"],
                subscription_date: subscription["created_at"],
                subscription_update_date: subscription["updated_at"],
                next_billing_at: subscription["next_billing_at"] || null,
                subscription_status: subscription["status"],
                cancelled_at: subscription["cancelled_at"] || null,
                pause_date: subscription["pause_date"] || null,
                resume_date: subscription["resume_date"] || null,
              });
            });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  async redeem() {
    const response = await this.api
      .getPromoCode(this.redeem_code)
      .pipe(take(1))
      .toPromise();

    if (response["success"]) {
      //  now check if user has used that code
      const user = await this.api
        .getUserDataFromFirbease(localStorage.getItem("useruuid"))
        .pipe(take(1))
        .toPromise();

      if (user.promoCodeUsed) {
        if (user.promoCodeUsed.includes(this.redeem_code)) {
          this.api.showCustomAlertError("You have already used this code");
        } else {
          // apply code and update user used code
          this.extendSubscription(response["data"][0].days);
        }
      } else {
        // apply code and update user used code
        this.extendSubscription(response["data"][0].days);
      }
    } else {
      this.api.showCustomAlertError("Something went wrong try again!");
    }
  }

  extendSubscription(days) {
    this.api
      .updateSubscritpion(
        this.userSubscription.id,
        this.userSubscription.plan_id,
        this.userSubscription.current_term_end + +days * 86400,
        this.userSubscription.status,
        days
      )
      .subscribe(async (result) => {
        if (result["success"]) {
          const res = result["data"];
          await this.api.saveProfile(localStorage.getItem("useruuid"), {
            plan_id: res["plan_id"],
            subscription_id: res["id"],
            subscription_date: res["created_at"],
            subscription_update_date: res["updated_at"],
            next_billing_at: res["next_billing_at"] || null,
            subscription_status: res["status"],
            cancelled_at: res["cancelled_at"] || null,
            pause_date: res["pause_date"] || null,
            resume_date: res["resume_date"] || null,
            promoCodeUsed: firebase.firestore.FieldValue.arrayUnion(
              this.redeem_code
            )

            // arrayUnion(
            //   this.redeem_code
            // ),
          });
          this.api.showCustomAlertSuccess("Code applied successfully");
        } else {
          this.api.showCustomAlertError(result["message"]);
        }
      });
  }
}
