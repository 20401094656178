import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.css"],
})
export class UpdatePasswordComponent implements OnInit {
  updatePasswordForm: FormGroup;
  constructor(private api: ApiService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.updatePasswordForm = this.formBuilder.group({
      password: new FormControl("", Validators.compose([Validators.required])),
    });
  }

  async updatePassword(password) {
    if (password.password != "") {
      try {
        (await this.api.getCurrentUser()).updatePassword(password.password);
        this.updatePasswordForm.reset();
        this.api.showCustomAlertSuccess("Password updated Successfully");
      } catch (error) {
        this.api.showCustomAlertError(error.message);
      }
    }
  }
}
