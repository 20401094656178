import { Component, OnDestroy, OnInit } from "@angular/core";
import { ApiService } from "../services/api.service";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { take } from 'rxjs/operators';

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent implements OnInit, OnDestroy {
  showPlans = false;
  showAnnualBanner = false;
  showManageMyPass = false;
  userdata;
  private_data;
  userSubscription;
  currentPlan;
  plans = [];
  isUSDPlan = false
  userDataSubscription: Subscription;
  userPrivateDataSubscription: Subscription;
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private router: Router
  ) { }

  ngOnInit() {
    this.userDataSubscription = this.api
      .getUserDataFromFirbease(localStorage.getItem("useruuid"))
      .subscribe((data) => {
        if (this.userPrivateDataSubscription)
          this.userPrivateDataSubscription.unsubscribe()
        this.userPrivateDataSubscription = this.api.getUserPrivateData(localStorage.getItem("useruuid")).subscribe(p => {
          this.private_data = p
        })
        this.userdata = data;
        this.showHidePlans();
        if (!this.userdata.devAccount) {
          this.api
            .getSubscritpion(this.userdata.subscription_id)
            .subscribe((subscription) => {
              this.userSubscription = subscription;
              this.isUSDPlan = this.api.isUSDPlan(this.userSubscription['plan_id'])
              this.api.saveProfile(localStorage.getItem("useruuid"), {
                plan_id: subscription["plan_id"],
                subscription_id: subscription["id"],
                subscription_date: subscription["created_at"],
                subscription_update_date: subscription["updated_at"],
                next_billing_at: subscription["next_billing_at"] || null,
                subscription_status: subscription["status"],
                cancelled_at: subscription["cancelled_at"] || null,
                pause_date: subscription["pause_date"] || null,
                resume_date: subscription["resume_date"] || null,
                billing_period_unit:
                  subscription["billing_period_unit"] || null,
              });

              this.api.getPlans().subscribe((plans) => {
                const allPlans = plans["list"];
                this.currentPlan = allPlans.filter(
                  (plan) => plan.plan.id === this.userdata.plan_id
                );

                let letter = "u"
                if (subscription['status'] == 'cancelled') letter = 'r'
                this.plans = allPlans.filter(
                  (plan) => plan.plan.id !== this.userdata.plan_id && plan.plan.id.slice(plan.plan.id.length - 1) == letter
                );
                // this.plans = allPlans.filter(
                //   (plan) =>
                //     plan.plan.price > this.currentPlan[0].plan.price ||
                //     plan.plan.period_unit === "year"
                // );


              });
            });

        } else {
          this.router.navigateByUrl("home/mysubscription");
        }
      });
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }

    if (this.userPrivateDataSubscription) {
      this.userPrivateDataSubscription.unsubscribe()
    }
  }

  upgrade() {
    this.showPlans = !this.showPlans;
  }

  viewSubscription() {
    this.router.navigateByUrl("home/mysubscription");
  }

  showHidePlans() {
    if (this.userdata.billing_period_unit === "month") {
      this.showPlans = true;
      this.showAnnualBanner = true;
      // if (this.userdata.subscription_status == 'active')
      // this.showManageMyPass = true
    } else if (this.userdata.billing_period_unit === "year") {
      const currentDate = new Date().getTime() / 1000;
      if (currentDate > this.userdata.next_billing_at || Math.abs(this.userdata.next_billing_at - currentDate) <= 5259492)
        this.showPlans = true;
      else this.showPlans = false;

      // showAnnualBanner hide/show logic
      if (this.userdata.cancelled_at)
        if (currentDate > this.userdata.cancelled_at || Math.abs(this.userdata.cancelled_at - currentDate) <= 2592000)
          this.showAnnualBanner = true;
        else this.showAnnualBanner = false;
      if (this.userdata.next_billing_at)
        if (currentDate > this.userdata.next_billing_at || Math.abs(this.userdata.next_billing_at - currentDate) <= 2592000)
          this.showAnnualBanner = true;
        else this.showAnnualBanner = false;
      // show manage my pass logic
      // if (this.userdata.subscription_status == 'active')
      //   if (this.userdata.cancelled_at)
      //     if (currentDate > this.userdata.cancelled_at || Math.abs(this.userdata.cancelled_at - currentDate) >= 2592000)
      //       this.showManageMyPass = true;
      //     else this.showManageMyPass = false;
      // if (this.userdata.next_billing_at)
      //   if (currentDate > this.userdata.next_billing_at || Math.abs(this.userdata.next_billing_at - currentDate) >= 2592000)
      //     this.showManageMyPass = true;
      //   else this.showManageMyPass = false;

    } else {
      this.showPlans = false;
      this.showAnnualBanner = false;
      // this.showManageMyPass = false;
    }

    if (this.api.isLifetimeUser(this.userdata.email))
      this.showAnnualBanner = false;
  }

  openCheckoutExisting(planid, monthlyflag?) {
    if (this.isUSDPlan) {
      planid = "usa_amz365u_premium_50";

      if (monthlyflag)
        planid = "pyramid-premium-rolling-usa"
    }
    // if the plan is same and cancelled then we reactivete the same plan
    if (this.userdata.plan_id == planid) {
      this.reactivate(this.userdata.subscription_id);
      return;
    }

    this.api.cbInstance.openCheckout({
      hostedPage: () => {
        // this.loading = true;
        const data = {
          plan_id: planid,
          customer_id: this.userdata.customer_id,
        };
        return this.http
          .post(`${this.api.apiurl}/checkoutExisting`, data)
          .toPromise();
      },
      loaded: () => { },
      error: () => {
        // this.loading = false;
        // this.ref.markForCheck();
        // this.errMsg = true;
        this.api.showCustomAlertError(
          "An Error occurred, please contact support"
        );
      },
      close: () => {
        // this.loading = false;
        // this.ref.detectChanges();
      },
      success: async (hostedPageId) => {
        // this has to be commednted as now web hook is updateing the data
        // let hostedPageData = await this.http
        //   .post(`${this.api.apiurl}/retriveHostedPage`, {
        //     hosted_page_id: hostedPageId,
        //   })
        //   .toPromise();

        // // this is to handle monthly case
        // let days = hostedPageData["content"]["subscription"].billing_period_unit == 'month' ? 30 : 365


        // const resp = await this.api.updateSubscritpion(hostedPageData["content"]["subscription"].id, hostedPageData["content"]["subscription"].plan_id, this.userSubscription.next_billing_at || 0 + days * 86400, 'active', 0).pipe(take(1)).toPromise();

        // if (resp['success']) {

        //   this.api
        //     .saveProfile(localStorage.getItem("useruuid"), {
        //       plan_id: resp["data"].plan_id,
        //       subscription_id: resp
        //       ["data"].id,
        //       subscription_date:
        //         resp
        //         ["data"].created_at,
        //       subscription_update_date:
        //         resp
        //         ["data"].updated_at,
        //       next_billing_at:
        //         resp
        //         ["data"].next_billing_at,
        //       previous_plan_id: this.userdata.plan_id,
        //       subscription_status:
        //         resp
        //         ["data"].status,
        //     })
        //     .then(() => {
        //       this.api.showCustomAlertSuccess(
        //         "Subscription upgraded successfully"
        //       );
        //     });
        // } else {
        //   this.api.showCustomAlertError(
        //     "An Error occurred, please contact support"
        //   );
        // }

        this.showPlans = false;

        this.api.showCustomAlertSuccess(
          "Subscription upgraded successfully"
        );
      },
      step: (value) => {
        // value -> which step in checkout
      },
    });


  }

  reactivate(id) {
    this.api.reactivateSubscription(id).subscribe((result) => {
      this.api
        .saveProfile(localStorage.getItem("useruuid"), {
          plan_id: result["plan_id"],
          subscription_id: result["id"],
          subscription_date: result["created_at"],
          subscription_update_date: result["updated_at"],
          next_billing_at: result["next_billing_at"] || null,
          subscription_status: result["status"],
          cancelled_at: result["cancelled_at"] || null,
        })
        .then(() => {
          this.api.showCustomAlertSuccess(
            "Subscription reactivated successfully"
          );
          this.router.navigateByUrl("home/mysubscription");
        });
    });
  }

  sendFeedBack(type) {
    let url = 'https://pyramidwifi.typeform.com/to/'
    if (type == 'feedback') {
      if (!this.private_data.feedback_submitted) {
        if (this.api.isUSDPlan(this.userSubscription["plan_id"]))
          window.open(`${url}GetMdyvw#email=${this.userdata.email}&name=${this.userdata.full_name.split(" ")[0]}`, '_blank');
        else
          window.open(`${url}o6JJzTuN#email=${this.userdata.email}&name=${this.userdata.full_name.split(" ")[0]}`, '_blank');

      }
    } else if ('beta') {
      window.open(`${url}UG8m4Vgr#email=${this.userdata.email}&name=${this.userdata.full_name.split(" ")[0]}`, '_blank');
    }

  }
}
