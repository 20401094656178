<div id="test1" class="col s12">
  <div class="container" *ngIf="userSubscription">
    <!-- feedback -->
    <div class="card hide-on-small-only"
      *ngIf="private_data && !(private_data.feedback_submitted) && (userSubscription.status == 'active' || userSubscription.status == 'in_trial') && (userSubscription.plan_id == 'pyramid-premium-monthly' || userSubscription.plan_id == 'pyramid-premium-rolling-usa')">
      <div class="card-content" align="center">
        <p *ngIf="isUSDPlan"> Tell us what you think of Pyramid for your chance to win a Lifetime Pass worth $229</p>
        <p *ngIf="!isUSDPlan">Tell us what you think of Pyramid for your chance to win a Lifetime Pass worth £229</p>
      </div>
      <div align="center" class="card-action">
        <a class="waves-effect waves-light btn" (click)="sendFeedBack('feedback')">Share thoughts to win</a>
      </div>

    </div><br>

    <!-- early access -->
    <div class="card"
      *ngIf="private_data && !(private_data.beta_used) && !(userSubscription.plan_id.includes('beta') || userSubscription.plan_id.includes('premium'))">
      <div class="card-content" align="center">
        <h5>Get Early Access</h5>
        <br> <span> Fancy early access to the next generation of Pyramid with up to 3x faster & all new apps? 🔥 </span>
      </div>
      <div align="center" class="card-action">
        <a class="waves-effect waves-light btn" (click)="sendFeedBack('beta')">Request Access</a>
      </div>
    </div><br>

    <!-- expired passs -->
    <div class="row" *ngIf="userSubscription.status == 'cancelled'">
      <div class="col s12 m12">
        <div class="card blue-grey darken-1">
          <div class="card-content white-text">
            <span class="card-title">Pass Expired</span>
            <p>Your Pass has expired. Pick an option below to reactivate Pyramid and enjoy instant privacy on all your
              devices.</p>
          </div>

        </div>
      </div>
    </div>
    <!-- <div class="card red lighten-2" *ngIf="userSubscription.status == 'cancelled'">

      <div class="card-content white-text" align="center">
        <p>Your pass is expired. Want to continue using Pyramid?</p>
      </div>
      <div align="center" class="card-action">
        <a class="waves-effect waves-light btn" (click)="viewSubscription()">Pick a plan</a>
      </div>


    </div><br> -->

    <!-- set to expire non renewing -->
    <!-- <div class="card orange lighten-2" *ngIf="userSubscription.status == 'non_renewing'">

      <div class="card-content black-text" align="center">
        <br> <span> Your pass is set to cancel. Don't want to lose access?</span>
      </div>
      <div align="center" class="card-action">
        <a class="waves-effect waves-light btn" (click)="reactivate(userSubscription.id)">Keep My
          Subscription</a>
      </div>

    </div> -->

    <div class="row" *ngIf="userSubscription.status == 'non_renewing'">
      <div class="col s12 m12">
        <div class="card blue-grey darken-1">
          <div class="card-content white-text">
            <span class="card-title">Your Account is Scheduled to Cancel</span>
            <p>Your Pass is set to expire. Pick an option below to keep Pyramid active and continue to enjoy instant
              privacy on all your devices.</p>
          </div>
          <div class="card-action">
            <a routerLink="/home/mysubscription"> Keep Current Subscription</a>
          </div>

        </div>
      </div>
    </div>





    <!-- annual splash banner -->
    <!-- <div class="card" *ngIf="showAnnualBanner">
      <div class="card-image">
        <img *ngIf="!isUSDPlan" src="/assets/annual-saver.png">
        <img *ngIf="isUSDPlan" src="/assets/annual-saver-usa.png">
      </div>
      <div class="card-content" align="center">
        <br> <span> Upgrade to our £49 Annual Pass and save 30% vs paying monthly, equivalent to 3 months
          free access or a cash saving of £22.</span>
        <br> <span *ngIf="!isUSDPlan"> Get 12 months Pyramid Premium for the price of 7 when you switch to annual
          billing. Go from £6.99 to
          only
          £3.99 a month when you pay up-front ( £35 saving vs monthly).</span>

        <span *ngIf="isUSDPlan"> Love online freedom and saving money? We got you!
          Our new Pyramid 12 Month bundle helps you lock in some serious savings - pay up-front for the year and save
          25% vs monthly.</span>
      </div>
      <div align="center" class="card-action">

        <a class="waves-effect waves-light btn" target="_blank" href="https://www.pyramidwifi.com/pass">Upgrade and
          Save</a>

        <a *ngIf="userSubscription.status == 'active' || userSubscription.status == 'in_trial'"
          class="waves-effect waves-light btn" (click)="openCheckoutExisting('amz365u_premium')">Upgrade and
          Save</a>

      </div>
    </div> -->

    <!-- annual splash banner -->
    <div class="row" *ngIf="showAnnualBanner && userdata.plan_id != 'switch-and-save-yearly-premium'">
      <div class="col s12 m12 l6">
        <div class="card">
          <div class="card-image">
          </div>
          <div class="card-content">
            <div class="hide-on-small-only" *ngIf="isUSDPlan" align="center"><img width="200"
                src='/assets/monthly-pass-usa.svg'></div>
            <div class="hide-on-small-only" *ngIf="!isUSDPlan" align="center"><img width="200"
                src='/assets/monthly-pass.svg'></div>
            <div class="hide-on-small-only" align="center"><button
                *ngIf="userSubscription.status == 'active' || userSubscription.status == 'in_trial'"
                [disabled]="userSubscription.status == 'active' || userSubscription.status == 'in_trial'"
                class="waves-effect waves-light btn-large white black-text">Current
                Plan</button>
              <button class="hide-on-small-only" *ngIf="userSubscription.status == 'non_renewing'"
                class="waves-effect waves-light btn-large blue white-text"
                (click)="reactivate(userSubscription.id)">Keep Monthly</button>

              <button class="hide-on-small-only" *ngIf="userSubscription.status == 'cancelled'"
                (click)="openCheckoutExisting('pyramid-premium-monthly',true)"
                class="waves-effect waves-light btn-large white blue-text">Choose Monthly</button>
            </div>

            <div class="hide-on-med-and-up" *ngIf="!isUSDPlan" align="center">
              <p>Current Plan £6.99 /mo</p>
            </div>
            <div class="hide-on-med-and-up" *ngIf="isUSDPlan" align="center">
              <p>Current Plan $8.99 /mo</p>
            </div>

          </div>
        </div>
      </div>
      <div class="col s12 m12 l6">
        <div class="card">
          <div class="card-image">
          </div>
          <div class="card-content">
            <div *ngIf="isUSDPlan" align="center"><img width="200" src="/assets/yearly-pass-usa.svg"></div>
            <div *ngIf="!isUSDPlan" align="center"><img width="200" src="/assets/yearly-pass.svg"></div>
            <div align="center"><button *ngIf="userSubscription.status != 'cancelled'"
                (click)="openCheckoutExisting('amz365u_premium')"
                class="waves-effect waves-light btn-large purple darken-4 white-text">Switch and
                Save</button>
            </div>

            <div align="center"><button *ngIf="userSubscription.status == 'cancelled'"
                (click)="openCheckoutExisting('amz365u_premium')"
                class="waves-effect waves-light btn-large purple darken-4 white-text">Choose Yearly</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div align="center"> <a (click)="viewSubscription()" class="waves-effect waves-light btn-small">Manage My Pass</a>
    </div>

  </div>

  <!-- <div class="container" *ngIf="showPlans">
    <div class="card">
      <div class="card-content">
        <h5>Upgrade Offer</h5>
        <ul class="collapsible" materialize="collapsible">
          <li *ngFor="let plan of plans">
            <div class="collapsible-header"><i class="material-icons">place</i>{{plan.plan.name}}</div>
            <div class="collapsible-body">
              <div class="card-body">
                <h5 class="card-title">{{plan.plan.name}}</h5>
                <h6 class="card-subtitle mb-2 text-muted">£{{plan.plan.price/100}}/{{plan.plan.period_unit}}</h6>
                <p class="card-text">{{plan.plan.description}}</p>
                <p class="card-text" *ngIf="plan.plan.trial_period">Trial Period {{plan.plan.trial_period}}
                  {{plan.plan.trial_period_unit}}(s)
                </p>
                <button class="btn btn-primary" (click)="openCheckoutExisting(plan.plan.id)">Upgrade</button>
              </div>
            </div>

          </li>
        </ul>
      </div>
      <div class="card-action" align="center">
        <button class="btn btn-primary" (click)="viewSubscription()">View My Subscription</button>
      </div>

    </div><br>

  </div> -->
</div>